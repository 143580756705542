import React, { useEffect, useState } from 'react';
import { Header } from './components/layout/header';
import { AppContent } from './components/layout/content';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { fetcher } from './utils/fetcher';
import useAuth from './contexts/AuthContext';

export const UserApp = () => {
  const { authState, appLoading } = useAuth();
  const location = useLocation();
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const [rsvp, setRsvp] = useState<false | string>(
    location.pathname === '/rsvp' ? '' : false
  );
  const [loading, setLoading] = useState(true);
  const [menuOpened, setMenuOpened] = useState(false);

  useEffect(() => {
    if (!appLoading) {
      if (location.pathname === '/rsvp') {
        if (authState.loggedIn) {
          navigate('/');
          setRsvp(false);
          setLoading(false);
        } else {
          console.log(location);
          const nonce = params.get('nonce');
          if (nonce) {
            validateNonce(nonce);
          } else {
            navigate('/');
            setRsvp(false);
            setLoading(false);
          }
        }
      } else {
        setRsvp(false);
        setLoading(false);
      }
    }
  }, [location, params, appLoading, authState]);

  const validateNonce = (nonce: string) => {
    fetcher.user
      .inviteReceived(nonce)
      .then(() => {
        setRsvp(nonce);
      })
      .catch(() => {
        navigate('/');
        setRsvp(false);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Header
        rsvp={!!rsvp}
        menuOpened={menuOpened}
        switchMenu={() => setMenuOpened((prev) => !prev)}
      />
      <AppContent
        rsvp={rsvp}
        loading={loading || appLoading}
        menuOpened={menuOpened}
        closeMenu={() => setMenuOpened(false)}
      />
    </>
  );
};
