import React from 'react';
import './style.scss';
import { Login } from '../../login';
import { Nft } from '../../nft';
import useAuth, { AuthState } from '../../../contexts/AuthContext';
import { AppLoader } from '../appLoader';
import { Rsvp } from '../../rsvp';
import { AppMenu } from '../appMenu';
import clsx from 'clsx';

interface Props {
  rsvp: string | false;
  loading: boolean;
  menuOpened: boolean;
  closeMenu: () => any;
}

export const AppContent = ({ rsvp, loading, menuOpened, closeMenu }: Props) => {
  const { authState } = useAuth();

  return (
    <div className='app-content'>
      <div className='app-content-background-wrapper'>
        <img
          src={authState.loggedIn || loading ? '/li-bg.jpeg' : '/lo-bg.jpeg'}
          alt='Background'
          className='app-content-background'
        />
      </div>
      <div className='app-content-content-wrapper'>
        <Content loading={loading} state={authState} rsvp={rsvp} />
      </div>
      <div
        className={clsx('app-content-menu-wrapper', menuOpened && 'visible')}
        onClick={() => closeMenu()}
      >
        <AppMenu opened={menuOpened} closeMenu={closeMenu} />
      </div>
    </div>
  );
};

interface ContentProps {
  loading: boolean;
  rsvp: string | false;
  state: AuthState;
}

const Content = ({ state, loading, rsvp }: ContentProps) => {
  if (loading) {
    return <AppLoader />;
  }
  if (rsvp) {
    return <Rsvp nonce={rsvp} />;
  }
  if (state.loggedIn) {
    return <Nft />;
  }
  return <Login />;
};
