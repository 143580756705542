import React from 'react';
import './style.scss';
import useLanguage from '../../../contexts/Language';

export const RsvpHead = () => {
  const { language } = useLanguage();
  const wrap = language.rsvp.head;
  const body = language.nft;

  return (
    <div className='rsvp-head'>
      <h1 className='rsvp-head-title'>{body.title}</h1>
      <p className='rsvp-head-subtitle first'>{body.subtitle.first}</p>
      <p className='rsvp-head-subtitle'>{body.subtitle.second}</p>
      <div className='rsvp-head-body'>
        <div className='rsvp-hb-line'>
          <h4 className='rsvp-hb-title'>{body.dateTitle}</h4>
          <p className='rsvp-hb-value'>{body.dateValue}</p>
        </div>
        <div className='rsvp-hb-line'>
          <h4 className='rsvp-hb-title'>{body.timeTitle}</h4>
          <p className='rsvp-hb-value'>{body.timeValueFirstLine}</p>
        </div>
        <div className='rsvp-hb-line'>
          <h4 className='rsvp-hb-title'>{body.venueTitle}</h4>
          <p className='rsvp-hb-value'>
            {body.venueValueFirstLine} <br />
            {body.venueValueSecondLine}
          </p>
        </div>
        <div className='rsvp-hb-line'>
          <h4 className='rsvp-hb-title'>{body.dressTitle}</h4>
          <p className='rsvp-hb-value'>{body.dressValue}</p>
        </div>
      </div>
      <p className='rsvp-head-footer'>
        {wrap.footer.beforeDate}
        <b>{wrap.footer.date}</b>
        {wrap.footer.afterDate}.<br />
        {wrap.footer.secondLine}
      </p>
    </div>
  );
};
