import React, { useEffect, useState } from 'react';
import './style.scss';
import useLanguage from '../../contexts/Language';
import clsx from 'clsx';

export const Nft = () => {
  const { language } = useLanguage();
  const [nftLoaded, setNftLoaded] = useState(false);
  const lang = language.nft;

  useEffect(() => {
    setNewTimeout();
  }, []);

  const setNewTimeout = () => {
    setTimeout(() => {
      const video = document.getElementById('nft-video') as any;
      if (video?.readyState === 4) {
        setNftLoaded(true);
      } else {
        setNewTimeout();
      }
    }, 50);
  };

  return (
    <div className='nft'>
      <div className='nft-video-wrapper'>
        <video
          id='nft-video'
          autoPlay
          playsInline
          loop
          muted
          className={clsx('nft-video', nftLoaded && 'visible')}
        >
          <source src='/invite.mp4' type='video/mp4' />
        </video>
        <img
          src='/nft-bg.png'
          className={clsx('nft-video-cover', !nftLoaded && 'visible')}
        />
      </div>
      <div className='nft-description'>
        <h1 className='nft-desc-title'>{lang.title}</h1>
        <p className='nft-desc-subtitle'>{lang.subtitle.alternative}</p>
        <div className='nft-db'>
          <div className='nft-db-line'>
            <h4 className='nft-db-title'>{lang.dateTitle}</h4>
            <p className='nft-db-value'>{lang.dateValue}</p>
          </div>
          <div className='nft-db-line'>
            <h4 className='nft-db-title'>{lang.timeTitle}</h4>
            <p className='nft-db-value'>{lang.timeValueFirstLine}</p>
          </div>
          <div className='nft-db-line'>
            <h4 className='nft-db-title'>{lang.venueTitle}</h4>
            <p className='nft-db-value'>
              {lang.venueValueFirstLine}
              <br />
              {lang.venueValueSecondLine}
            </p>
          </div>
          <div className='nft-db-line'>
            <h4 className='nft-db-title'>{lang.dressTitle}</h4>
            <p className='nft-db-value'>{lang.dressValue}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
