import React from 'react';
import './style.scss';
import RawDropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import clsx from 'clsx';

interface Props {
  options: string[];
  active: string | undefined;
  onClick: (selected: string) => any;
  className?: string;
  label?: string;
  valid?: boolean;
  helperMessage?: string;
}

export const Dropdown = ({
  options,
  active,
  onClick,
  className,
  label,
  valid = true,
  helperMessage,
}: Props) => (
  <div
    className={clsx(
      'dropdown-wrapper',
      !valid && 'dropdown-wrapper-invalid',
      className
    )}
  >
    {label && <div className='label'>{label}</div>}
    <RawDropdown
      options={options}
      value={active}
      onChange={(option) => onClick(option.value)}
      placeholder='Please select'
      className={clsx('custom-dropdown', className)}
      controlClassName='custom-dropdown-label-wrapper'
      placeholderClassName='custom-dropdown-label'
      arrowOpen={<div className='custom-dropdown-arrow rotated' />}
      arrowClosed={<div className='custom-dropdown-arrow ' />}
      menuClassName='custom-dropdown-items-wrapper'
    />
    {helperMessage && <div className='helper-text'>{helperMessage}</div>}
  </div>
);
