import React from 'react';
import './style.scss';
import clsx from 'clsx';

interface Props {
  value: string;
  onChange: (newValue: string) => any;
  className?: string;
  placeholder?: string;
  onEnterPress?: () => any;
  autoFocus?: boolean;
  valid?: boolean;
  helperMessage?: string;
  type?: 'text' | 'password' | 'phone';
  disabled?: boolean;
  id?: string;
  label?: string;
  multi?: boolean;
}

const notNumeric = new RegExp(/\D/);

export const Input = ({
  value,
  onChange,
  className = '',
  autoFocus = false,
  placeholder = undefined,
  onEnterPress,
  valid = true,
  helperMessage,
  type = 'text',
  disabled = false,
  id,
  label,
  multi,
}: Props) => {
  const handleChange = (e) => {
    const newValue = e.target.value;
    if (type === 'phone' && !multi) {
      const toTest = newValue[0] === '+' ? newValue.substring(1) : newValue;
      if (notNumeric.test(toTest)) return;
    }
    onChange(newValue);
  };

  const onKeyDown = (e) => {
    if (onEnterPress && e.key === 'Enter') {
      onEnterPress();
    }
  };

  const props = {
    id,
    value,
    onChange: handleChange,
    onKeyDown,
    className: clsx('input', multi && 'input-multi'),
    autoFocus,
    placeholder,
    disabled,
  };

  return (
    <div
      className={clsx(
        'input-wrapper',
        !valid && 'input-wrapper-invalid',
        className
      )}
    >
      {label && <div className='label'>{label}</div>}
      {multi ? <textarea {...props} /> : <input {...props} type={type} />}
      {helperMessage && <div className='helper-text'>{helperMessage}</div>}
    </div>
  );
};
