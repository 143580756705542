import React from 'react';
import './style.scss';
import { RsvpHead } from './head';
import { RsvpBody } from './body';
import { RsvpLegal } from './legal';

interface Props {
  nonce: string;
}

export const Rsvp = ({ nonce }: Props) => {
  return (
    <div className='rsvp-wrapper'>
      <RsvpHead />
      <RsvpBody nonce={nonce} />
      <RsvpLegal />
    </div>
  );
};
