import React from 'react';
import './style.scss';
import clsx from 'clsx';

interface Props {
  opened: boolean;
  onClick: () => any;
  className?: string;
}

export const MenuButton = ({ opened, onClick, className }: Props) => (
  <button className={clsx('menu-button', className)} onClick={onClick}>
    <div className={clsx('menu-subbutton', 'left', !opened && 'visible')}>
      <ClosedButton />
    </div>
    <div className={clsx('menu-subbutton', 'right', opened && 'visible')}>
      <OpenedButton />
    </div>
  </button>
);

const ClosedButton = () => (
  <svg
    width='30'
    height='27'
    viewBox='0 0 30 27'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <line y1='26' x2='30.0007' y2='26' stroke='#333333' strokeWidth='2' />
    <line y1='13.5' x2='30.0007' y2='13.5' stroke='#333333' strokeWidth='2' />
    <line y1='1' x2='30.0007' y2='1' stroke='#333333' strokeWidth='2' />
  </svg>
);

const OpenedButton = () => (
  <svg
    width='25'
    height='25'
    viewBox='0 0 25 25'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect
      x='0.787109'
      y='1.83984'
      width='1.48837'
      height='32'
      transform='rotate(-45 0.787109 1.83984)'
      fill='#333333'
    />
    <rect
      x='23.4141'
      y='0.787598'
      width='1.48837'
      height='32'
      transform='rotate(45 23.4141 0.787598)'
      fill='#333333'
    />
  </svg>
);
