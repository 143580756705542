import React, { useState } from 'react';
import './style.scss';
import { Input } from '../../general/input';
import {
  RsvpSalutation,
  salutationOptions,
} from '../../../../../shared/interfaces';
import { Dropdown } from '../../general/dropdown';
import { Button } from '../../general/button';
import { isPhoneValid } from '../../../../../shared/isPhoneValid';
import { fetcher } from '../../../utils/fetcher';
import useLanguage from '../../../contexts/Language';
import useAlert from '../../../contexts/alert';
import useAuth from '../../../contexts/AuthContext';
import { isEmailValid } from '../../../../../shared/isEmailValid';

type InvalidData<T> = {
  value: T;
  valid: false;
  reason: string;
};

type ValidData<T> = {
  value: T;
  valid: true;
};

type Data<T> = InvalidData<T> | ValidData<T>;

interface Props {
  nonce: string;
}

export const RsvpBody = ({ nonce }: Props) => {
  const { login } = useAuth();
  const { language } = useLanguage();
  const alert = useAlert();
  const lang = language.rsvp.body;
  const [email, setEmail] = useState<Data<string>>({
    value: '',
    valid: true,
  });
  const [salutation, setSalutation] = useState<
    Data<RsvpSalutation | undefined>
  >({
    value: undefined,
    valid: true,
  });
  const [lastName, setLastName] = useState<Data<string>>({
    value: '',
    valid: true,
  });
  const [firstName, setFirstName] = useState<Data<string>>({
    value: '',
    valid: true,
  });
  const [mobile, setMobile] = useState<Data<string>>({
    value: '',
    valid: true,
  });
  const [currentCar, setCurrentCar] = useState<Data<string>>({
    value: '',
    valid: true,
  });
  const [personCount, setPersonCount] = useState<Data<1 | 2>>({
    value: 1,
    valid: true,
  });
  const [dietary, setDietary] = useState<Data<string>>({
    value: '',
    valid: true,
  });
  const [remarks, setRemarks] = useState<Data<string>>({
    value: '',
    valid: true,
  });
  const [submitLoading, setSubmitLoading] = useState(false);

  const handleChange = (setter: (v: any) => void, v: any) => {
    setter({ valid: true, value: v });
  };

  const validate = (): boolean => {
    let result = true;
    if (!isEmailValid(email.value)) {
      setEmail((prev) => ({
        ...prev,
        valid: false,
        reason:
          prev.value.length > 0
            ? language.errors.email.incorrect
            : language.errors.email.empty,
      }));
      result = false;
    }
    if (!salutation.value || !salutationOptions.includes(salutation.value)) {
      setSalutation((prev) => ({
        ...prev,
        valid: false,
        reason: language.errors.salutation,
      }));
      result = false;
    }
    if (lastName.value.length === 0) {
      setLastName((prev) => ({
        ...prev,
        valid: false,
        reason: language.errors.lastName,
      }));
      result = false;
    }
    if (firstName.value.length === 0) {
      setFirstName((prev) => ({
        ...prev,
        valid: false,
        reason: language.errors.firstName,
      }));
      result = false;
    }
    if (!isPhoneValid(mobile.value)) {
      setMobile((prev) => ({
        ...prev,
        valid: false,
        reason: language.errors.phone,
      }));
      result = false;
    }
    if (currentCar.value.length === 0) {
      setCurrentCar((prev) => ({
        ...prev,
        valid: false,
        reason: language.errors.car,
      }));
      result = false;
    }
    if (![1, 2].includes(personCount.value)) {
      setPersonCount((prev) => ({
        ...prev,
        valid: false,
        reason: language.errors.personCount,
      }));
      result = false;
    }
    return result;
  };

  const scrollToTop = () => {
    const firstEl = document.getElementById('first-form-element');
    if (firstEl) {
      firstEl.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleSubmitClick = () => {
    if (!validate()) {
      return scrollToTop();
    }
    setSubmitLoading(true);
    fetcher.user
      .submitRsvp(nonce, email.value, {
        // @ts-ignore
        salutation: salutation.value,
        lastName: lastName.value,
        firstName: firstName.value,
        mobile: mobile.value,
        currentCar: currentCar.value,
        personCount: personCount.value,
        dietaryRestrictions: dietary.value,
        remarks: remarks.value,
      })
      .then(() => {
        login(email.value)
          .then(() => setSubmitLoading(false))
          .catch(() => window.location.reload());
      })
      .catch((err) => {
        alert.showError(err);
        setSubmitLoading(false);
        scrollToTop();
      });
  };

  const formValid =
    salutation.valid &&
    lastName.valid &&
    firstName.valid &&
    mobile.valid &&
    currentCar.valid &&
    personCount.valid &&
    dietary.valid &&
    remarks.valid;

  return (
    <div className='rsvp-body' id='first-form-element'>
      <h1 className='rsvp-body-title'>{lang.title}</h1>
      <p className='rsvp-body-hint'>{lang.hint}</p>
      <div className='rsvp-body-line'>
        <Dropdown
          options={salutationOptions}
          active={salutation.value}
          onClick={(value) => handleChange(setSalutation, value)}
          label={`${lang.salutation} *`}
          className='rsvp-body-block rsvp-body-dropdown'
          valid={salutation.valid}
          helperMessage={salutation.valid ? undefined : salutation.reason}
        />
        <Input
          value={lastName.value}
          onChange={(value) => handleChange(setLastName, value)}
          label={`${lang.lastName} *`}
          className='rsvp-body-block'
          valid={lastName.valid}
          helperMessage={lastName.valid ? undefined : lastName.reason}
        />
      </div>
      <div className='rsvp-body-line'>
        <Input
          value={firstName.value}
          onChange={(value) => handleChange(setFirstName, value)}
          label={`${lang.firstName} *`}
          className='rsvp-body-block'
          valid={firstName.valid}
          helperMessage={firstName.valid ? undefined : firstName.reason}
        />
      </div>
      <div className='rsvp-body-line'>
        <Input
          value={email.value}
          onChange={(value) => handleChange(setEmail, value)}
          label={`${lang.email} *`}
          className='rsvp-body-block'
          valid={email.valid}
          helperMessage={email.valid ? undefined : email.reason}
        />
      </div>
      <div className='rsvp-body-line'>
        <Input
          value={mobile.value}
          onChange={(value) => handleChange(setMobile, value)}
          label={`${lang.phone} *`}
          type='phone'
          className='rsvp-body-block'
          valid={mobile.valid}
          helperMessage={mobile.valid ? undefined : mobile.reason}
        />
      </div>
      <div className='rsvp-body-line'>
        <Input
          value={currentCar.value}
          onChange={(value) => handleChange(setCurrentCar, value)}
          label={`${lang.car} *`}
          className='rsvp-body-block'
          valid={currentCar.valid}
          helperMessage={currentCar.valid ? undefined : currentCar.reason}
        />
      </div>
      <div className='rsvp-body-line'>
        <Dropdown
          options={['1', '2']}
          active={personCount.value.toString()}
          onClick={(value) => {
            const numeric = Number(value);
            handleChange(setPersonCount, isNaN(numeric) ? 1 : numeric);
          }}
          label={`${lang.personCount} *`}
          className='rsvp-body-block rsvp-body-dropdown'
          valid={personCount.valid}
          helperMessage={personCount.valid ? undefined : personCount.reason}
        />
      </div>
      <div className='rsvp-body-line'>
        <Input
          value={dietary.value}
          onChange={(value) => handleChange(setDietary, value)}
          label={lang.dietary}
          multi
          className='rsvp-body-block'
          valid={dietary.valid}
          helperMessage={dietary.valid ? undefined : dietary.reason}
        />
      </div>
      <div className='rsvp-body-line'>
        <Input
          value={remarks.value}
          onChange={(value) => handleChange(setRemarks, value)}
          label={lang.remarks}
          multi
          className='rsvp-body-block'
          valid={remarks.valid}
          helperMessage={remarks.valid ? undefined : remarks.reason}
        />
      </div>
      <div className='rsvp-body-button-wrapper'>
        <Button
          className='rsvp-body-button'
          loading={submitLoading}
          onClick={handleSubmitClick}
          disabled={!formValid}
        >
          {lang.submit}
        </Button>
      </div>
      {/*<p className='rsvp-body-button-hint'>{lang.submitHint}</p>*/}
    </div>
  );
};
