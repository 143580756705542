export const english = {
  rsvp: {
    head: {
      footer: {
        beforeDate: 'Kindly RSVP by ',
        date: '22 February 2023',
        afterDate: '',
        secondLine:
          'Seats are limited and available on a first-come, first-served basis.',
      },
    },
    body: {
      title: 'My Details',
      hint: 'Fields with * are required',
      email: 'Email',
      salutation: 'Salutation',
      lastName: 'Surname',
      firstName: 'Given Name',
      phone: 'Mobile No.',
      car: 'Current Car',
      personCount: 'Total No. Of Pax Attending',
      dietary: 'Dietary restrictions, if any',
      remarks: 'Remarks',
      submit: 'Submit',
      submitHint: `By clicking submit, you will be creating a wallet to receive the NFT invite from Bentley Singapore.`,
    },
    legal: {
      title: 'Personal Data Consent Declaration',
      p1: `By providing the information set out in the this form, I agree and consent
      to Wearnes Automotive Pte. Ltd. and its related corporations
      (collectively, “Wearnes”) collecting, using, disclosing and sharing
      amongst themselves my personal data therein as well as in the records of
      Wearnes from time to time for purposes reasonably required by Wearnes to
      provide me with the products and services I am requesting for.`,
      p2: `Such purposes are set out in Wearnes Data Protection Policy, which is
      accessible at www.wearnesauto.com or available on request, which I confirm
      I have read and understood. I am aware that by signing up for this product
      or service, I am also consenting to Wearnes collecting, using and
      disclosing my personal data for marketing, advertising and promotional
      purposes, including the purposes described as “Additional Purposes” in the
      Data Protection Policy. I can withdraw such consent at any time by
      contacting the Data Protection Officer at dpo.sg@wearnes.com`,
    },
  },
  login: {
    title: 'WELCOME TO EXCLUSIVE NEW BENTAYGA EWB LAUNCH',
    email: 'Email',
    login: 'Log In',
  },
  nft: {
    title: 'Discover Your Sanctuary',
    subtitle: {
      first:
        'We invite you to step into the sanctuary of the new Bentayga Extended Wheelbase Azure – Bentley’s most luxurious and comfortable SUV to date. A car that grants both the driver and the driven the ability to create their own haven of comfort and well-being.',
      second:
        'Boasting First-in-World features such as the Bentley Airline Seat Specification with auto-climate and advanced postural adjustment, the Bentley Diamond Illumination, and the longest rear room space in its class; the Bentayga Extended Wheelbase Azure is the car that uplifts your spirits on every journey.',
      alternative:
        'Thank you for your RSVP. A booking confirmation will be sent to you shortly.',
    },
    dateTitle: 'Date',
    dateValue: 'Thursday, 2 March 2023',
    timeTitle: 'Time',
    timeValueFirstLine: '6:30PM - 9PM',
    timeValueSecondLine: 'Registration from 6PM',
    venueTitle: 'Venue',
    venueValueFirstLine: 'Bentley Showroom',
    venueValueSecondLine: '45 Leng Kee Road Singapore 159103',
    dressTitle: 'Dress Code',
    dressValue: 'Smart Casual',
  },
  header: {
    walletAddress: 'Wallet Address',
    logout: 'Log Out',
  },
  errors: {
    email: {
      empty: 'Please enter the mail address.',
      incorrect: 'The email address is incorrect.',
      unknown: "This email isn't registered.",
    },
    salutation: 'Please select salutation.',
    lastName: 'Please enter surname.',
    firstName: 'Please enter given name.',
    phone: 'Please enter valid mobile number.',
    car: 'Please enter your current car model.',
    personCount: 'Please select no. of pax attending',
  },
};

export type Language = typeof english;

export const chinese: Language = {
  rsvp: {
    head: {
      footer: {
        beforeDate: '请在 ',
        date: '2023 年 2 月 22 日',
        afterDate: '之前回复',
        secondLine: '座位有限，先到先得',
      },
    },
    body: {
      title: '我的个人资料',
      hint: '带 * 的字段是必需的',
      email: '电子邮件地址',
      salutation: '称呼',
      lastName: '姓',
      firstName: '名',
      phone: '手机号码',
      car: '目前拥有的汽车',
      personCount: '出席人数',
      dietary: '饮食限制',
      remarks: '评论',
      submit: '呈请',
      submitHint:
        '通过提交此表格，您将创建一个钱包来接收来自 宾利新加坡的邀请NFT。',
    },
    legal: {
      title: 'Personal Data Consent Declaration',
      p1: `By providing the information set out in the this form, I agree and consent
      to Wearnes Automotive Pte. Ltd. and its related corporations
      (collectively, “Wearnes”) collecting, using, disclosing and sharing
      amongst themselves my personal data therein as well as in the records of
      Wearnes from time to time for purposes reasonably required by Wearnes to
      provide me with the products and services I am requesting for.`,
      p2: `Such purposes are set out in Wearnes Data Protection Policy, which is
      accessible at www.wearnesauto.com or available on request, which I confirm
      I have read and understood. I am aware that by signing up for this product
      or service, I am also consenting to Wearnes collecting, using and
      disclosing my personal data for marketing, advertising and promotional
      purposes, including the purposes described as “Additional Purposes” in the
      Data Protection Policy. I can withdraw such consent at any time by
      contacting the Data Protection Officer at dpo.sg@wearnes.com`,
    },
  },
  login: {
    title: '欢迎来到全新添越长轴距版的独家发布',
    email: '电子邮件地址',
    login: '登录',
  },
  nft: {
    title: '驰骋跃动，奢逸领航',
    subtitle: {
      first:
        '我们诚邀您踏入全新添越长轴距版的奢逸领航——宾利迄今为止最豪华、最舒适的SUV。一款让驾驶者和乘客都能创造属于自己的非凡之旅。',
      second:
        '新添越长轴距版拥有傲视同侪的座舱空间， 后排座椅引入全球首创的自动温度感应功能和坐姿调节两大功能，可带来非凡的驾乘体验。甄选奢华材质匠造的宾利流光菱形格套件为内饰设计倍添现代美学格调。全新添越长轴距版, 能让您在每次旅程中得以享受极致的尊享体验。',
      alternative: '谢谢您的回复。我们将跟进一封电子邮件以确认您的出席。',
    },
    dateTitle: '日期',
    dateValue: '3月2日, 星期四',
    timeTitle: '时间',
    timeValueFirstLine: '630PM 至 9PM',
    timeValueSecondLine: '从下午6PM开始登记',
    venueTitle: '地点',
    venueValueFirstLine: '宾利展示厅',
    venueValueSecondLine: '45 Leng Kee Road Singapore 159103',
    dressTitle: '着装要求',
    dressValue: '时尚休闲',
  },
  header: {
    walletAddress: '钱包地址',
    logout: '登出',
  },
  errors: {
    email: {
      empty: '请输入邮件地址。',
      incorrect: '电子邮件地址不正确。',
      unknown: '此电子邮件未注册。',
    },
    salutation: '请选择称呼。',
    lastName: '请输入姓氏。',
    firstName: '请输入名字。',
    phone: '请输入有效的手机号码。',
    car: '请输入您当前的汽车型号。',
    personCount: '请选择出席人数。',
  },
};
