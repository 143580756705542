import {
  APIUser,
  IAdminAllUsersResponse,
  IAdminInviteUsersResponse,
  IAdminLoginResponse,
  IAdminRsvpResponse,
  RSVP,
} from '../../../shared/interfaces';

type ResponseOK<T> = {
  success: true;
  data: T;
};

type ResponseErr<T> = {
  success: false;
  error: any;
};

type Response<T> = ResponseOK<T> | ResponseErr<T>;

type Sender = () => Promise<globalThis.Response>;

abstract class APIFetcher {
  private headers: { [key: string]: string } = {};

  private processRequest = <T>(sender: Sender): Promise<T> => {
    return new Promise<T>((resolve, reject) => {
      sender()
        .then((r) => {
          return new Promise<Response<T>>((resolve, reject) => {
            const status = r.status;
            r.text()
              .then((text) => {
                const json = text.length > 0 ? JSON.parse(text) : undefined;
                if (status !== 200 && status !== 304) {
                  resolve({ success: false, error: json });
                } else {
                  resolve({ success: true, data: json });
                }
              })
              .catch((err) => reject(err));
          });
        })
        .then((response: Response<T>) => {
          if (response.success) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((err) => reject(err));
    });
  };

  protected get = <T>(url: string, params?: any): Promise<T> => {
    const finalUrl = params
      ? `${url}?${new URLSearchParams(params).toString()}`
      : url;
    const sender: Sender = () => {
      return fetch(finalUrl, {
        method: 'GET',
        headers: this.headers,
      });
    };
    return this.processRequest<T>(sender);
  };

  protected post = <T>(url: string, payload?: any): Promise<T> => {
    const sender: Sender = () => {
      return fetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': ' application/json',
          ...this.headers,
        },
        body: JSON.stringify(payload),
      });
    };
    return this.processRequest<T>(sender);
  };

  public setTokenHeader = (token: string) => {
    this.headers = { 'x-auth': token };
  };

  public clearHeaders = () => {
    this.headers = {};
  };
}

class UserFetcher extends APIFetcher {
  public getInfo = () => {
    return this.get<APIUser>('/api/users/getinfo');
  };

  public submitRsvp = (nonce: string, email: string, form: RSVP) => {
    return this.post('/api/users/submitrsvp', { form, nonce, email });
  };

  public inviteReceived = (nonce: string) => {
    return this.post('/api/users/invitereceived', { nonce });
  };
}

class AdminFetcher extends APIFetcher {
  public login = (password: string) => {
    return this.post<IAdminLoginResponse>('/api/admin/login', { password });
  };

  public validateToken = () => {
    return this.get('/api/admin/validatetoken');
  };

  public createUsers = (amount: number) => {
    return this.post('/api/admin/createusers', { amount });
  };

  public getAllUsers = () => {
    return this.get<IAdminAllUsersResponse>('/api/admin/allusers');
  };

  public updateNftStatus = () => {
    return this.get('/api/admin/updatenftstatus');
  };

  public setPending = () => {
    return this.post('/api/admin/setpending');
  };

  public inviteUsers = () => {
    return this.get<IAdminInviteUsersResponse>('/api/admin/invite');
  };

  public regenerateLinks = (nonceLength: number) => {
    return this.post<IAdminInviteUsersResponse>('/api/admin/regeneratelinks', {
      nonceLength,
    });
  };

  public getRsvp = (address: string) => {
    return this.get<IAdminRsvpResponse>('/api/admin/rsvp', { address });
  };
}

class Fetcher {
  private readonly _user: UserFetcher;
  private readonly _admin: AdminFetcher;

  constructor() {
    this._user = new UserFetcher();
    this._admin = new AdminFetcher();
  }

  get user(): UserFetcher {
    return this._user;
  }

  get admin(): AdminFetcher {
    return this._admin;
  }
}

export const fetcher = new Fetcher();
