import React, { createContext, useContext, useEffect, useRef } from 'react';
import './style.scss';
import clsx from 'clsx';
import useArrayState from '../../utils/useArrayState';
import { ejectErrorMessage } from '../../utils/ejectErrorMessage';

enum AlertTypes {
  DEFAULT,
  ERROR,
}

type InputMessage = string | any;

interface Message {
  message: string;
  delay: number;
  id: number;
  type: AlertTypes;
}

interface AlertContextProviderProps {
  children: React.ReactNode;
}

interface IAlert {
  show: (message: string, delay?: number) => void;
  showError: (message: InputMessage, delay?: number) => void;
}

// @ts-ignore
const AlertContext = createContext<IAlert>();

export const AlertContextProvider = (props: AlertContextProviderProps) => {
  const {
    value: messages,
    push: addMessage,
    remove: removeMessage,
  } = useArrayState<Message>([]);
  const id = useRef(0);

  useEffect(() => {
    if (messages.length === 0) id.current = 0;
  }, [messages]);

  const commonShow = (
    message: string,
    delay: number = 3000,
    type: AlertTypes = AlertTypes.DEFAULT
  ) => {
    addMessage({ message, delay, id: id.current, type });
    id.current = id.current + 1;
  };

  const show = (message: string, delay?: number) =>
    commonShow(message, delay, AlertTypes.DEFAULT);

  const showError = (message: InputMessage, delay?: number) => {
    const text =
      ejectErrorMessage(message) || 'Something went wrong. Please try again';
    commonShow(text, delay, AlertTypes.ERROR);
  };

  return (
    <AlertContext.Provider value={{ show, showError }}>
      <div className='alert-messages-wrapper'>
        {messages.map((message) => (
          <MessageComponent
            removeSelf={() => removeMessage((m) => m.id === message.id)}
            message={message}
            key={message.id}
          />
        ))}
      </div>
      {props.children}
    </AlertContext.Provider>
  );
};

interface MessageComponentProps {
  message: Message;
  removeSelf: () => void;
}

const MessageComponent = ({ message, removeSelf }: MessageComponentProps) => {
  useEffect(() => {
    const timeout = setTimeout(removeSelf, message.delay);
    return () => clearTimeout(timeout);
  }, []);

  const specificClassName = (() => {
    switch (message.type) {
      case AlertTypes.ERROR:
        return 'alert-message-error';
      default:
        return 'alert-message-default';
    }
  })();

  return (
    <div
      className={clsx('alert-message', specificClassName)}
      onClick={removeSelf}
    >
      {message.message}
    </div>
  );
};

const useAlert = () => useContext(AlertContext);

export default useAlert;
