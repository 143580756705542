import React from 'react';
import './style.scss';
import useAuth from '../../../contexts/AuthContext';
import { WalletAddress } from '../header/walletAddress';
import { LogoutIcon } from '../header/logoutIcon';
import { Button } from '../../general/button';
import useLanguage from '../../../contexts/Language';
import { LanguageSwitch } from '../header/languageSwitch';
import clsx from 'clsx';

interface Props {
  opened: boolean;
  closeMenu: () => any;
}

export const AppMenu = ({ opened, closeMenu }: Props) => {
  const { logout, authState } = useAuth();
  const { language } = useLanguage();

  if (!authState.loggedIn) return null;

  return (
    <div
      className={clsx('app-menu', opened && 'visible')}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <div className='app-menu-content'>
        {/*<WalletAddress
          address={authState.userData.address}
          className='app-menu-address'
          onClick={(address: string) => {
            closeMenu();
            window.open(`${process.env.OPENSEA_URL}${address}`, '_blank');
          }}
        />*/}
        <Button
          variant='contained'
          onClick={() => {
            logout();
            closeMenu();
          }}
          className='app-menu-logout-button'
        >
          <div className='app-menu-logout'>
            <LogoutIcon className='app-menu-logout-icon' color='#FFF' />
            <p className='app-menu-logout-wording'>{language.header.logout}</p>
          </div>
        </Button>
        <div className='app-menu-language'>
          <LanguageSwitch useMobile={false} />
        </div>
      </div>
    </div>
  );
};
