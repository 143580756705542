import React from 'react';
import './style.scss';
import useLanguage, { LangType } from '../../../../contexts/Language';
import clsx from 'clsx';

interface Props {
  useMobile: boolean;
}

export const LanguageSwitch = ({ useMobile }: Props) => {
  const { setLanguage, activeLang } = useLanguage();

  return (
    <div
      className={clsx('language-switch', useMobile && 'language-switch-mobile')}
    >
      <h4
        className={clsx(
          'lang-opt',
          'lang-opt-en',
          activeLang === LangType.EN && 'lang-opt-active'
        )}
        onClick={() => setLanguage(LangType.EN)}
      >
        English
      </h4>
      <div className='lang-sprtr' />
      <h4
        className={clsx(
          'lang-opt',
          'lang-opt-cn',
          activeLang === LangType.CN && 'lang-opt-active'
        )}
        onClick={() => setLanguage(LangType.CN)}
      >
        中文
      </h4>
    </div>
  );
};
