import React from 'react';
import './style.scss';
import useLanguage from '../../../contexts/Language';

export const RsvpLegal = () => {
  const { language } = useLanguage();

  return (
    <div className='rsvp-legal'>
      <h4 className='rsvp-legal-title'>{language.rsvp.legal.title}</h4>
      <p className='rsvp-legal-paragraph'>{language.rsvp.legal.p1}</p>
      <p className='rsvp-legal-paragraph'>{language.rsvp.legal.p2}</p>
    </div>
  );
};
