import React from 'react';
import './style.scss';
import { Button } from '../../general/button';
import { LogoutIcon } from './logoutIcon';
import useAuth from '../../../contexts/AuthContext';
import useLanguage from '../../../contexts/Language';
import { LanguageSwitch } from './languageSwitch';
import clsx from 'clsx';
import { MenuButton } from './menuButton';

enum State {
  OUT,
  RSVP,
  IN,
}

interface Props {
  rsvp: boolean;
  menuOpened: boolean;
  switchMenu: () => any;
}

export const Header = ({ rsvp, menuOpened, switchMenu }: Props) => {
  const { logout, authState } = useAuth();

  const state: State = rsvp
    ? State.RSVP
    : authState.loggedIn
    ? State.IN
    : State.OUT;

  return (
    <div className='app-header-wrapper'>
      <div
        className={clsx(
          'app-header',
          state === State.RSVP && 'app-header-rsvp',
          state === State.IN && 'app-header-in'
        )}
      >
        <div className='app-header-logo-wrapper'>
          <img src='/logo.png' alt='logo' className='app-header-logo' />
        </div>
        {authState.loggedIn && (
          <UserData
            address={
              state === State.IN ? authState.userData.address : undefined
            }
            logout={logout}
          />
        )}
        <div className='app-header-right'>
          <div className='lang-switch-wrapper'>
            <LanguageSwitch useMobile={state !== State.IN} />
          </div>
          <MenuButton
            opened={menuOpened}
            onClick={switchMenu}
            className='header-menu-button'
          />
        </div>
      </div>
    </div>
  );
};

interface UserDataProps {
  address?: string;
  logout: () => any;
}

const UserData = ({ address, logout }: UserDataProps) => {
  const { language } = useLanguage();

  return (
    <div className='app-header-data'>
      {/*{address && (
        <WalletAddress address={address} className='app-header-data-address' />
      )}*/}
      <Button variant='contained' onClick={logout}>
        <div className='app-header-logout'>
          <LogoutIcon className='app-header-logout-icon' color='#FFF' />
          <p className='app-header-logout-wording'>{language.header.logout}</p>
        </div>
      </Button>
    </div>
  );
};
