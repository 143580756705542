import React, { useState } from 'react';
import './style.scss';
import { Input } from '../general/input';
import { Button } from '../general/button';
import { isEmailValid } from '../../../../shared/isEmailValid';
import useAuth from '../../contexts/AuthContext';
import useLanguage from '../../contexts/Language';

type InvalidEmail = {
  email: string;
  valid: false;
  reason: string;
};

type ValidEmail = {
  email: string;
  valid: true;
};

type Email = InvalidEmail | ValidEmail;

export const Login = () => {
  const { login } = useAuth();
  const { language } = useLanguage();
  const [data, setData] = useState<Email>({ email: '', valid: true });
  const [loading, setLoading] = useState(false);

  const onChange = (newValue: string) => {
    setData({ email: newValue, valid: true });
  };

  const handleLoginClick = () => {
    if (!data.valid) return;
    if (!isEmailValid(data.email)) {
      return setData((prev) => ({
        ...prev,
        valid: false,
        reason:
          prev.email.length > 0
            ? language.errors.email.incorrect
            : language.errors.email.empty,
      }));
    }
    setLoading(true);
    login(data.email)
      .catch(() => {
        setData((prev) => ({
          ...prev,
          valid: false,
          reason: language.errors.email.unknown,
        }));
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className='login'>
      <h1 className='login-title'>{language.login.title}</h1>
      <Input
        className='login-email'
        value={data.email}
        onChange={onChange}
        onEnterPress={handleLoginClick}
        placeholder={language.login.email}
        autoFocus
        valid={data.valid}
        helperMessage={data.valid ? undefined : data.reason}
      />
      <Button
        variant='contained'
        onClick={handleLoginClick}
        className='login-button'
        disabled={!data.valid}
        loading={loading}
      >
        {language.login.login}
      </Button>
    </div>
  );
};
