export enum NftStatus {
  NONE = 'N/A',
  PENDING = 'Pending',
  DONE = 'Done',
}

export enum PreUserStatus {
  NONE = 'none',
  INVITE_SENT = 'Invite sent',
  INVITE_RECEIVED = 'Invite received',
}

export type IPreUserClean = {
  address: string;
  privateKey: string;
  nonce: string;
  status: PreUserStatus;
  nftStatus: NftStatus;
};

export enum RsvpSalutation {
  MR = 'Mr.',
  MS = 'Ms.',
  MRS = 'Mrs.',
  MDM = 'Mdm.',
  DR = 'Dr.',
  PROF = 'Prof.',
  CAPT = 'Capt.',
}

export const salutationOptions = [
  RsvpSalutation.MR,
  RsvpSalutation.MS,
  RsvpSalutation.MRS,
  RsvpSalutation.MDM,
  RsvpSalutation.DR,
  RsvpSalutation.PROF,
  RsvpSalutation.CAPT,
];

export interface RSVP {
  salutation: RsvpSalutation;
  firstName: string;
  lastName: string;
  mobile: string;
  currentCar: string;
  personCount: 1 | 2;
  dietaryRestrictions: string;
  remarks: string;
}

export type IUserClean = {
  address: string;
  privateKey: string;
  email: string;
  rsvp: RSVP;
};

export interface APIPreUser {
  address: string;
  nftStatus: NftStatus;
  status: PreUserStatus;
  nonce: string;
}

export interface APIUser {
  address: string;
  email: string;
}

export interface IAdminLoginResponse {
  token: string;
}

export interface IAdminAllUsersResponse {
  init: APIPreUser[];
  withNft: APIPreUser[];
  invited: APIPreUser[];
  users: APIUser[];
}

export interface IAdminInviteUsersResponse {
  inviteLinks: string[];
}

export interface IAdminRsvpResponse {
  rsvp: RSVP;
}
