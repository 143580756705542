import React from 'react';
import './style.scss';
import clsx from 'clsx';
import { Loader } from '../loader';

interface Props {
  children: React.ReactNode | string;
  variant?: 'contained' | 'outlined';
  className?: string;
  onClick?: () => any;
  loading?: boolean;
  disabled?: boolean;
}

export const Button = ({
  children,
  className,
  variant = 'contained',
  onClick,
  loading,
  disabled,
}: Props) => (
  <button
    className={clsx(
      'button',
      `button-${variant}`,
      className,
      loading && 'button-loading',
      disabled && 'button-disabled'
    )}
    onClick={!loading && !disabled ? onClick : undefined}
  >
    <div className='button-body-wrapper'>
      {typeof children === 'string' ? (
        <p className='button-body'>{children}</p>
      ) : (
        children
      )}
    </div>
    <div className='button-loader-wrapper'>
      <Loader size={20} thickness={2} />
    </div>
  </button>
);
