import React from 'react';
import './style.scss';
import clsx from 'clsx';

interface Props {
  size?: number;
  color?: string;
  thickness?: number;
  className?: string;
}

export const Loader = ({
  size = 80,
  color = '#FFF',
  thickness = 6,
  className,
}: Props) => {
  return (
    <div
      className={clsx('loader-wrapper', className)}
      style={{ width: size, height: size }}
    >
      <div
        className='loader'
        style={{
          border: `${thickness}px solid ${color}`,
          borderColor: `${color} transparent ${color} transparent`,
        }}
      />
    </div>
  );
};
