import React, { createContext, useContext, useEffect, useState } from 'react';
import { chinese, english, Language } from '../utils/language';
import { useSearchParams } from 'react-router-dom';

export enum LangType {
  EN = 'en',
  CN = 'cn',
}

type LangSet = {
  lang: LangType;
  set: Language;
};

interface ILanguageContext {
  language: Language;
  setLanguage: (lang: LangType) => void;
  activeLang: LangType;
}

// @ts-ignore
const LanguageContext = createContext<ILanguageContext>();

interface Props {
  children: React.ReactNode;
}

export const LanguageContextProvider = ({ children }: Props) => {
  const [params, setParams] = useSearchParams();
  const [langSet, setLangSet] = useState<LangSet>({
    lang: LangType.EN,
    set: english,
  });

  useEffect(() => {
    const lang = params.get('lang');
    if (lang === 'cn') {
      setLangSet({ lang: LangType.CN, set: chinese });
      document.documentElement.style.fontFamily = "'SimHei', sans-serif";
    } else {
      setLangSet({ lang: LangType.EN, set: english });
      document.documentElement.style.fontFamily = "'Bentley', sans-serif";
    }
  }, [params]);

  const setLanguage = (lang: LangType) => {
    setParams((prev) => {
      prev.set('lang', lang);
      return prev;
    });
  };

  return (
    <LanguageContext.Provider
      value={{
        language: langSet.set,
        activeLang: langSet.lang,
        setLanguage,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

const useLanguage = () => useContext(LanguageContext);

export default useLanguage;
