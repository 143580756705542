import React from 'react';

interface Props {
  color?: string;
  className?: string;
}

export const LogoutIcon = ({ color = '#333', className }: Props) => (
  <svg
    width='19'
    height='14'
    viewBox='0 0 19 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
  >
    <path
      d='M11.575 12.1562C11.575 12.2839 11.5243 12.4063 11.434 12.4965C11.3438 12.5868 11.2214 12.6375 11.0938 12.6375H2.59375C2.46611 12.6375 2.34371 12.5868 2.25345 12.4965C2.1632 12.4063 2.1125 12.2839 2.1125 12.1562V2.59375C2.1125 2.46611 2.1632 2.34371 2.25345 2.25345C2.34371 2.1632 2.46611 2.1125 2.59375 2.1125H11.0938C11.2214 2.1125 11.3438 2.1632 11.434 2.25345C11.5243 2.34371 11.575 2.46611 11.575 2.59375V4.71875C11.575 4.87291 11.6362 5.02075 11.7452 5.12976C11.8542 5.23876 12.0021 5.3 12.1562 5.3C12.3104 5.3 12.4583 5.23876 12.5673 5.12976C12.6763 5.02075 12.7375 4.87291 12.7375 4.71875V2.59375C12.7375 2.1578 12.5643 1.73971 12.2561 1.43144C11.9478 1.12318 11.5297 0.95 11.0938 0.95H2.59375C2.1578 0.95 1.73971 1.12318 1.43144 1.43144C1.12318 1.73971 0.95 2.1578 0.95 2.59375V12.1562C0.95 12.5922 1.12318 13.0103 1.43144 13.3186C1.73971 13.6268 2.1578 13.8 2.59375 13.8H11.0938C11.5297 13.8 11.9478 13.6268 12.2561 13.3186C12.5643 13.0103 12.7375 12.5922 12.7375 12.1562V10.0312C12.7375 9.87709 12.6763 9.72925 12.5673 9.62024C12.4583 9.51124 12.3104 9.45 12.1562 9.45C12.0021 9.45 11.8542 9.51124 11.7452 9.62024C11.6362 9.72925 11.575 9.87709 11.575 10.0312V12.1562Z'
      fill={color}
      stroke={color}
      strokeWidth='0.1'
    />
    <path
      d='M16.0656 6.76204L13.8698 4.56728L13.8698 4.56727C13.7606 4.45814 13.6993 4.31013 13.6993 4.15579C13.6993 4.00146 13.7606 3.85344 13.8698 3.74431C13.9789 3.63518 14.1269 3.57387 14.2813 3.57387C14.4356 3.57387 14.5836 3.63518 14.6927 3.74431L17.8802 6.93177L17.8802 6.93181L17.8449 6.96717C17.8944 7.01652 17.9336 7.07514 17.9604 7.13968C17.9872 7.20422 18.001 7.27342 18.001 7.34329C18.001 7.41317 17.9872 7.48236 17.9604 7.5469C17.9336 7.61144 17.8944 7.67007 17.8449 7.71942L16.0656 6.76204ZM16.0656 6.76204H6.84375C6.68959 6.76204 6.54175 6.82328 6.43274 6.93229C6.32374 7.04129 6.2625 7.18914 6.2625 7.34329C6.2625 7.49745 6.32374 7.64529 6.43274 7.7543C6.54175 7.8633 6.68959 7.92454 6.84375 7.92454H16.0656L13.8698 10.1193L13.8698 10.1193C13.7606 10.2284 13.6993 10.3765 13.6993 10.5308C13.6993 10.6851 13.7606 10.8331 13.8698 10.9423C13.9789 11.0514 14.1269 11.1127 14.2813 11.1127C14.4356 11.1127 14.5836 11.0514 14.6927 10.9423L17.8802 7.75482L16.0656 6.76204Z'
      fill={color}
      stroke={color}
      strokeWidth='0.1'
    />
  </svg>
);
