import React, { createContext, useContext, useEffect, useState } from 'react';
import { fetcher } from '../utils/fetcher';
import { APIUser } from '../../../shared/interfaces';
import localStorage from '../utils/localStorage';

type LoggedIn = {
  loggedIn: true;
  userData: APIUser;
};

type LoggedOut = {
  loggedIn: false;
};

export type AuthState = LoggedIn | LoggedOut;

interface IAuth {
  appLoading: boolean;
  login: (email: string) => Promise<void>;
  logout: () => void;
  authState: AuthState;
}

// @ts-ignore
const AuthContext = createContext<IAuth>();

interface Props {
  children: React.ReactNode;
}

export const AuthContextProvider = ({ children }: Props) => {
  const [loading, setLoading] = useState(true);
  const [auth, setAuth] = useState<AuthState>({ loggedIn: false });

  useEffect(() => {
    const existing = localStorage.getEmail();
    if (!existing) {
      setLoading(false);
    } else {
      validateLogin(existing).then(() => setLoading(false));
    }
  }, []);

  const login = (email: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      fetcher.user.setTokenHeader(email);
      fetcher.user
        .getInfo()
        .then((response) => {
          localStorage.clear();
          localStorage.setEmail(email);
          setAuth({
            loggedIn: true,
            userData: response,
          });
          resolve();
        })
        .catch((err) => {
          fetcher.user.clearHeaders();
          reject(err);
        });
    });
  };

  const validateLogin = (email: string): Promise<void> => {
    return new Promise((resolve) => {
      fetcher.user.setTokenHeader(email);
      fetcher.user
        .getInfo()
        .then((response) => {
          setAuth({
            loggedIn: true,
            userData: response,
          });
          resolve();
        })
        .catch(() => {
          localStorage.clear();
          fetcher.user.clearHeaders();
          resolve();
        });
    });
  };

  const logout = () => {
    fetcher.user.clearHeaders();
    localStorage.clear();
    setAuth({ loggedIn: false });
  };

  return (
    <AuthContext.Provider
      value={{
        appLoading: loading,
        authState: auth,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

export default useAuth;
