import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.scss';
import { AuthContextProvider } from './contexts/AuthContext';
import { LanguageContextProvider } from './contexts/Language';
import { BrowserRouter } from 'react-router-dom';
import { AlertContextProvider } from './contexts/alert';
import { UserApp } from './UserApp';

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <AlertContextProvider>
    <AuthContextProvider>
      <BrowserRouter>
        <LanguageContextProvider>
          <UserApp />
        </LanguageContextProvider>
      </BrowserRouter>
    </AuthContextProvider>
  </AlertContextProvider>
);
